<template lang="pug">
  .top-bar.row.align-items-center
    .col-lg-6.col-md-12.d-flex.add-new
      AppAddNewButton(
        v-if="hasEditPermission() && !isHidden('add-new-button')"
        name="add-new"
        @click="$emit('add-new')"
      )
      AppSearch.m-l-10.app-search(
        v-if="!isHidden('search-input')"
        @update="$emit('search', { search_value: $event })"
      )
      AppCheckbox.w-100(
        :value="isCarsWithAlerts"
        :label="$t('shops_sources_car_matching.show_shops_with_alert_cars')"
        @change="$emit('toggle-cars-with-alerts')"
      )
    .col-lg-6.col-md-12.d-flex.align-items-center.justify-content-end.save-button
      AppSaveButton.m-r-10(
        v-if="hasEditPermission() && !isHidden('save-button')"
        short
        :disabled="!allowSave"
        title="actions.save_changes"
        @save="$emit('save')"
      )
      AppDownloadButton.download-button(
        v-if="!isHidden('download-button')"
        disabled
      )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { every, includes } from "lodash-es"

  const ALLOWED_ELEMENTS_TO_HIDE = ["add-new-button", "save-button", "search-input", "download-button"]

  export default {
    props: {
      allowSave: {
        type: Boolean,
        default: true
      },
      hiddenElements: {
        type: Array,
        default: () => new Array(),
        validator: array => {
          return every(array, item => includes(ALLOWED_ELEMENTS_TO_HIDE, item))
        }
      },
      isCarsWithAlerts: {
        type: Boolean,
        default: true
      }
    },
    components: {
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppDownloadButton: () => import("@/components/elements/AppButton/WithIcon/Download"),
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew"),
      AppSearch: () => import("@/components/elements/AppSearch")
    },

    mixins: [withPermissions],

    methods: {
      isHidden(element) {
        return includes(this.hiddenElements, element)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .top-bar
    height: auto
    margin-bottom: $topbar-margin-bottom

  .app-search-wrapper
    padding-left: 0
</style>
